import NotFound from '@/components/icons/NotFound';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

const Index = () => {
  const { locale, push } = useRouter();
  return (
    <div className='flex flex-col items-center justify-center my-5 sm:my-10'>
      <NotFound className='w-72' />
      <p className='text-xl font-extrabold'>
        {locale === 'en' ? 'Page Not Found' : 'صفحة غير موجوده'}
      </p>
      <p className='mt-2 w-80 text-center text-zinc-500 max-sm:text-[15px] sm:max-w-96'>
        {locale === 'en'
          ? 'Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarity unavailable.'
          : 'عذرًا، ولكن الصفحة التي تبحث عنها غير موجودة، أو تمت إزالتها، أو تم تغيير الاسم، أو أنها غير متاحة مؤقتًا.'}
      </p>
      <button
        onClick={() => push('/')}
        className='p-1 mt-3 text-white bg-black border border-black rounded-md w-72'
      >
        {locale === 'en' ? 'Return To Home Page' : 'العودة للصفحة الرئيسية'}
      </button>
    </div>
  );
};

export default Index;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
